export var utilities = {
  methods: {
    formatCurrency,
    formatTimeStamp,
  },
}

function formatCurrency (numberInCents) {
  const dollarFigure = numberInCents / 100
  return dollarFigure.toFixed(2)
}

function formatTimeStamp (timestamp) {
  const date = new Date()
  date.setTime(timestamp * 1000)
  return date.toLocaleString()
}
