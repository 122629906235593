<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <v-row>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                <v-toolbar-title>Product Details</v-toolbar-title>
                <v-spacer />
                <template v-if="loggedInUser.role == 'Administrator' && selectedProduct.isActive == true">
                  <v-btn
                    text
                    @click="deleteProduct"
                  >
                    DELETE PRODUCT
                  </v-btn>
                </template>
                <template v-if="loggedInUser.role == 'Administrator' && selectedProduct.isActive == false">
                  <v-btn
                    text
                    @click="restoreProduct"
                  >
                    RESTORE PRODUCT
                  </v-btn>
                </template>
                <template v-if="loggedInUser.role == 'ROLE_SMC' && selectedProduct.is_active == true && selectedProduct.direct_product == false">
                  <v-btn
                    text
                  >
                    DISABLE PRODUCT
                  </v-btn>
                </template>
                <template v-if="loggedInUser.role == 'ROLE_SMC' && selectedProduct.is_active == false && selectedProduct.direct_product == false">
                  <v-btn
                    text
                  >
                    ENABLE PRODUCT
                  </v-btn>
                </template>
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Record ID
                      </h3>
                      <br>
                      <p>{{ selectedProduct._id || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Product Name
                      </h3>
                      <br>
                      <p>{{ selectedProduct.name || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Description
                      </h3>
                      <br>
                      <p>{{ selectedProduct.description || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Default Price
                      </h3>
                      <br>
                      <p v-if="selectedProduct.default_price">
                        ${{ formatCurrency(selectedProduct.default_price) }}
                      </p>
                      <p v-else>
                        Not Provided
                      </p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Direct Product
                      </h3>
                      <br>
                      <p>{{ selectedProduct.direct_product || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Requires Health Statement
                      </h3>
                      <br>
                      <p>{{ selectedProduct.requires_health_statement || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                    >
                      <h3
                        class="darken-2"
                        style="font-size: 1rem; color: grey"
                      >
                        Requires Approval
                      </h3>
                      <br>
                      <p>{{ selectedProduct.requires_approval || "Not Provided" }}</p>
                      <v-divider />
                    </v-col>
                    <template v-if="selectedProduct.expires == true">
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Hard Expiry
                        </h3>
                        <br>
                        <p>{{ selectedProduct.expiry_date || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                    </template>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          sm="12"
          lg="12"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar flat>
                <v-toolbar-title>Product Entry Details</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-data-table
                :items="selectedProduct.product_entry"
                :headers="productEntryHeaders"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { utilities } from '../../../../mixins/utility'

  export default {
    name: 'Registrant',
    mixins: [utilities],
    data () {
      return {
        page: {
          title: 'Your Roster',
          icon: 'user',
        },
        breadcrumbs: [
          {
            text: 'Users',
            disabled: false,
            to: '/user/users/',
          },
          {
            text: 'User Details',
            disabled: true,
          },
        ],
        productEntryHeaders: [
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Prefix',
            value: 'prefix',
          },
          {
            text: 'Type',
            value: 'type',
          },
        ],
        auditHeaders: [
          {
            text: 'Reason',
            value: 'reason',
          },
          {
            text: 'Resource',
            value: 'route',
          },
          {
            text: 'Method',
            value: 'method',
          },
          {
            text: 'When',
            value: 'createdAt',
          },
        ],
        clientDetailTypes: [
          'General',
          'Location',
          'Medical',
        ],
        defaultClientDetailType: 'General',
      }
    },
    created () {
      this.$store.dispatch('viewProduct', this.$route.params.id)
    },
    computed: {
      ...mapGetters(['selectedProduct', 'loggedInUser']),
    },
    methods: {
      back () {
        this.$router.back()
      },
      changeClientDetailsScreen (screen) {
        this.defaultClientDetailType = screen
      },
      approve () {
        this.$store.dispatch('approveRegistrant', this.registrantQuery._id)
      },
      refreshAudit () {
        this.$store.dispatch('showUserAudit')
      },
      deleteProduct () {
        this.$store.dispatch('deleteProduct', this.$route.params.id)
        this.$router.push('/admin/products')
      },
      restoreProduct () {
        this.$store.dispatch('restoreProduct', this.$route.params.id)
        this.$router.go()
      },
    },
  }
</script>
